<template>
  <div :style="{ backgroundImage: `url(${bannerImage})` }" >
    <loading :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false">
    </loading>
    <!-- User Interface controls -->
    <b-alert v-model="flagAlertProfile" variant="warning" dismissible>
          ¡Aún no cuentas con perfil para ver tu programación! Ir a
          <b-link @click="goTo('createProfile')">Crear perfil</b-link>
    </b-alert>
    <b-alert v-model="flagAlertPetProfile" variant="warning" dismissible>
          ¡Aún no cuentas con mascotas registradas para ver tu programación! Ir a
          <b-link @click="goTo('createPet')">Crear mascotas</b-link>
    </b-alert>
    <b-alert v-model="flagAlertPayments" variant="warning" dismissible>
          ¡No cuentas con pagos registrados!          
    </b-alert>
    <b-alert v-model="flagAlertSchedule" variant="warning" dismissible>
      ¡No tienes servicios programados para tu mascota! 
    </b-alert>
    <b-alert v-model="flagDataUpdated" variant="success" dismissible>
          ¡Datos actualizados!           
    </b-alert>

    <h2 class="title">Pagos y servicios</h2>
    <p>Encuentra aquí tus pagos y detalle de ejecución de servicios.</p>

    <section 
      v-if="!flagAlertProfile && 
      !flagAlertPetProfile && 
      !flagAlertSchedule"
      style="min-height:100vh">

      <b-row>
        <b-col lg="8" offset-lg="2" style="text-align: right">
          <b-button 
            class="s-bg s-bg-text mr-1"
            size="md"            
            @click="refresh()">
            <strong>Refrescar</strong>                   
          </b-button> 
        </b-col>
      </b-row>

      <b-row v-for="item in items" v-bind:key="item.id">
        <b-col lg="8" offset-lg="2">
          <CCard
            class="mb-0"
            :style="`margin-top:0.5rem`"            
            :accent-color="+item.servicios_restantes > 0 ? 'info' : 'danger'">
            <CCardHeader >
              <b-row>
                <b-col lg="12">
                  <strong><h5>Concepto: {{item.articulo_id}}</h5></strong>
                </b-col>

                <b-col lg="6">
                  <strong>Id pago:</strong>  
                  {{item.idPago}}
                </b-col>
                <b-col lg="6">
                  <strong>Cantidad:</strong> {{item.cantidad}}
                </b-col>

                <b-col lg="6">
                  <strong>Fecha de pago:</strong>  
                  {{item.fecha_operacion != 'Fecha inválida' ? item.fecha_operacion : ''}}
                </b-col>
                <b-col lg="6">
                  <strong>Servicios restantes:</strong> {{item.servicios_restantes}}
                </b-col>

                <b-col lg="6">
                  <strong>Medio de pago: </strong>{{item.medioPago}} 
                </b-col>
                <b-col lg="6">
                  <CIcon name="cilMoney" size="lg" height="48" alt="Logo" />                                                                      
                  <strong>Valor:</strong> {{item.precio | thousand}}
                </b-col>
                
              </b-row>
              <br>
              <b-row>
                <b-col offset-lg="3" lg="6" sm="6">
                  <b-button 
                    class="mr-1"
                    size="sm"
                    style="width:100%;"
                    @click="openDetail(item)" >                    
                    Ver servicios          
                  </b-button> 
                </b-col>  
              </b-row>              
              <div class="card-header-actions">                             
                 
              </div>                                                                                                         
            </CCardHeader>

              <CCollapse :show="item.expandAccordion === 0">
                <CCardBody>
                  <b-row>
                    <b-col lg="6">
                      <strong>Estado de pago: </strong>{{item.pedido_estado != null ? item.pedido_estado.estado : ''}}                    </b-col>
                    <b-col lg="6">
                      <strong>Medio de pago: </strong>{{item.medioPago}} 
                    </b-col>
                  </b-row>
                </CCardBody>
              </CCollapse>                                       
          </CCard>
        </b-col>
      </b-row>
     
      <br>
    </section>
  </div>
</template>

<script>
import _ from 'lodash'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CommentsForm from '../comments/qualifyWalker';
import moment from 'moment';
export default {
  name: "Pagos",
  components: {Loading, CommentsForm},
  data () {
    return {
      loading: true,
      userProfile: null,
      hasUserProfile: false,
      userId: null,
      userDbId: null,            
      items: [],
      fields: [
        { key: "id", label: "id" },        
        { key: "pedido_id", label: "Id pago", sortable: true },
        { key: "fecha_operacion", label: "Fecha de pago", sortable: true },
        { key: "estado", label: "Estado", sortable: true },
        { key: "precio", label: "Pago total", sortable: true },        
        { key: "articulo_id", label: "Servicio", sortable: false },
        { key: "cantidad", label: "Cantidad servicios", sortable: false },
        { key: "servicios_restantes", label: "Servicios restantes", sortable: false },       
        { key: "actions", label: "Acciones" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      dismissCountDown: 4,
      dismissSecs: 4,
      flagAlertProfile: false,
      flagAlertPetProfile: false,
      flagDataUpdated: false,
      flagAlertSchedule: false,
      flagAlertPayments: false,
      wp: 'https://api.whatsapp.com/send?phone=573194115792&text=Hola Go Guau, ',
      bannerImage: require("@/assets/images/bonds3.png"),
    }
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  created () {    
    this.userId = this.$store.getters.userId;
    this.userDbId = this.$store.getters.userDbId;
    let userProfile = JSON.parse(this.$store.getters.userProfile);
    
    if (userProfile){
      this.userProfile = userProfile      
      this.hasUserProfile = true
      // this.loading = false
      if(userProfile.pets.length == 0){
        this.flagAlertPetProfile = true;
      }
      this.userDbId = this.userProfile.id      
      this.postGetClientPayments();
    } else {
      this.loading = false;
      this.flagAlertProfile = true;
    }
  },
  methods: {       
    postGetClientPayments() {
      let payload = {
        id: this.userDbId,
      };      
      let getOrders = this.$firebase.functions().httpsCallable('getOrdersByClient')
      getOrders(payload).then((result) => {
        let data = result.data.data;
        console.log('data: ', JSON.stringify(data))
        if(data.length == 0) {
          this.flagAlertPayments = true
        } else {
          data.map((item) => {
            const fechaPago = _.get(item, 'pago.fecha_pago', '')
            item.fecha_operacion = moment(fechaPago).format('dddd, DD/MMMM/YY')
            item.debe = item.debe == 'null' ?  0 : item.debe
            item.expandAccordion = 1
            item.idPago = _.get(item, 'pago.id', 'Pendiente')
            item.medioPago = _.get(item, 'pago.medio[0]', '')
          })
          this.items = data
        }
        // console.log(this.items)
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loading = false
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    refresh() {      
      this.loading = true
      this.userId = this.$store.getters.userId;
      this.userDbId = this.$store.getters.userDbId;
      let userProfile = JSON.parse(this.$store.getters.userProfile);

      if (userProfile){
        this.userProfile = userProfile      
        this.hasUserProfile = true
        this.userDbId = this.userProfile.id      
        this.postGetClientPayments()
      } else {
        this.loading = false
      }
    },
    openMap(item, index, button)  {
      // console.log(item.id)
      let payload = {
        serviceId: item.id
      }
      let getContextUrlTracking = this.$firebase.functions().httpsCallable('getUrlTracking')
      getContextUrlTracking(payload).then((result) => {
        const contextUrl = result.data.data                
        let routeData = this.$router.resolve({path: '/services/gps?' + contextUrl});
        window.open(routeData.href, '_blank');
      }).catch((error) => {
        console.log(error)
      })
    },
    goTo(to){
      if(to == 'createProfile'){
        this.$router.replace("/users/profile");
      } else if(to == 'createPet') {
        this.$router.replace("/pets/form/0");
      }
    },
    whatsapp(){          
      let message = this.wp +
        ' deseo información sobre los paseos y adiestramientos.'; 
      window.open(message, '_blank');
    },
    qualifyWalker(item, index, button) {
      console.log(item)
    },
    openDetail (item, index, button) {
      console.log(item)
      this.$router.push("/services/schedule/" + item.id)      
    }
  },
}
</script>

<style scoped> 

</style>